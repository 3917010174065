<template>
  <div class="currencies">
    <v-row
      class="pa-4"
    >
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-subtitle-1 font-weight-bold">{{ $t('currencies.title') }}</v-card-title>
          <v-card-text>
            <template v-for="(currency, index) in currencies">
              <div class="currency d-flex align-center justify-space-between" :key="`currency-${index}`">
                <span>{{ currency.description }}</span>
                <v-switch
                  v-model="currency.active"
                  :loading="currency.loading"
                  :disabled="currency.description === 'EUR' || currency.loading"
                  @change="currencyChange(currency)"
                />
              </div>
              <v-divider
                v-if="index !== currencies.length - 1"
                :key="`divider-${index}`"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Services from '@/services'

export default {
  name: 'Currencies',
  data () {
    return {
      currencies: []
    }
  },
  created () {
    this.currencies = this.$store.getters.allCurrencies.map(c => ({
      currencyId: c.id,
      active: c.isActive,
      description: c.description,
      loading: false
    }))
  },
  methods: {
    async currencyChange (item) {
      item.loading = true
      try {
        await Services.put('Currency/UpdateCurrency', {
          currencyId: item.currencyId,
          active: item.active
        })
        await this.$store.dispatch('currencies')
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } finally {
        item.loading = false
      }
    }
  }
}
</script>
